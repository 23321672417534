import React, { useState, useEffect } from 'react';

const carouselItems = [
  {
    title: "Get Rewarded for Volunteering",
    description: [
      "Find and register for opportunities that align with your skills and interests.",
      "Get verified for completed tasks to unlock rewards.",
      "Receive discounts/coupons or donate the value back to nonprofits."
    ],
  },
  {
    title: "Discover a New Way to Engage With Volunteers",
    description: [
      "Post and manage volunteer tasks.",
      "Verify tasks at checkout to unlock rewards, post thanks.",
      "Receive donations."
    ],
  },
  {
    title: "Grow Your Community Impact",
    description: [
      "Offer your products and services for noble causes via discounts/coupons or donations.",
      "Select sponsoring activities based on causes for nonprofits.",
      "Review impact measures of sponsored activities."
    ],
  },
];

const MakeAMarkSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselItems.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-5xl font-reckless mb-10 text-black">Take action</h2>
        <div className="flex justify-center space-x-4 mb-10">
          {carouselItems.map((item, index) => (
            <span
              key={index}
              className={`px-6 py-3 rounded-full font-gt-pressura text-black transition-all duration-300 ${
                index === currentIndex
                  ? 'bg-altruence-yellow'
                  : 'border border-black hover:bg-gray-100'
              }`}
            >
              {item.title}
            </span>
          ))}
        </div>
        <div className="mb-10 text-black font-aeonik max-w-2xl mx-auto">
          {carouselItems[currentIndex].description.map((point, index) => (
            <div key={index} className="flex items-start mb-4">
              <div className="bg-altruence-yellow rounded-full w-8 h-8 flex items-center justify-center mr-4 flex-shrink-0">
                <span className="font-gt-pressura font-bold">{index + 1}</span>
              </div>
              <p className="text-left text-lg leading-relaxed">{point}</p>
            </div>
          ))}
        </div>
        <button className="bg-altruence-yellow text-black px-8 py-4 rounded-full text-lg font-gt-pressura hover:bg-yellow-400 transition-colors duration-300">
          Sign Up For Early Access
        </button>
      </div>
    </section>
  );
};

export default MakeAMarkSection;