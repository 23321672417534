import React from 'react';

const SignupSection = () => (
  <section className="py-20 bg-altruence-yellow">
    <div className="container mx-auto px-6 text-center">
      <h2 className="text-4xl font-bold mb-6 text-black">Sign up for our waitlist today</h2>
      <p className="text-xl mb-10 text-black">Get early access to exclusive updates and features.</p>
      <form className="max-w-md mx-auto">
        <input 
          type="email" 
          placeholder="Your Email" 
          className="w-full px-4 py-2 mb-4 rounded-full text-black"
        />
        <button 
          type="submit" 
          className="bg-black text-white px-6 py-3 rounded-full text-lg w-full font-gt-pressura"
        >
          Join Waitlist
        </button>
      </form>
      <p className="mt-4 text-sm text-black">
        By signing up, you agree to our privacy policy and will receive commercial communications.
      </p>
    </div>
  </section>
);

export default SignupSection;