import React from 'react';

const Footer = () => (
  <footer className="bg-altruence-yellow py-10">
    <div className="container mx-auto px-6">
      <div className="flex justify-between items-center">
        <div className="text-2xl font-bold text-black">Altruence</div>
        <div className="flex space-x-6">
          {['About', 'Platform', 'Connect', 'Legal', 'Privacy'].map((item) => (
            <a key={item} href="#" className="text-black font-gt-pressura">
              {item}
            </a>
          ))}
        </div>
      </div>
      <div className="mt-6 text-sm text-black">© 2024 All Rights Reserved.</div>
    </div>
  </footer>
);

export default Footer;
