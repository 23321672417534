import React from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import MissionStatement from './components/MissionStatement';
import ConnectingSection from './components/ConnectingSection';
import MakeAMarkSection from './components/MakeAMarkSection';
import SignupSection from './components/SignupSection';
import Footer from './components/Footer';
import volunteerSVG from './assets/icons/ILLUSTRATION_VOLUNTEERS.svg';
import nonprofitSVG from './assets/icons/ILLUSTRATION_NONPROFITS.svg';
import businessSVG from './assets/icons/ILLUSTRATION_BUSSINESSES.svg';

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <HeroSection />
        <MissionStatement />
        <ConnectingSection 
          title="Connecting Volunteers"
          description="Altruence makes it easy for individuals to find volunteer opportunities."
          buttonText="Discover a New Way to Engage With Volunteers"
          bgColor="bg-altruence-green"
          svgSrc={volunteerSVG}
        />
        <ConnectingSection 
          title="Connecting Nonprofits"
          description="Access a network of committed volunteers ready to support your cause."
          buttonText="Discover a New Way to Engage With Volunteers"
          bgColor="bg-altruence-blue"
          reverse
          svgSrc={nonprofitSVG}
        />
        <ConnectingSection 
          title="Connecting Businesses"
          description="Enhance your community involvement and motivate your employees by engaging them in significant and fulfilling activities."
          buttonText="Grow Your Community Impact"
          bgColor="bg-altruence-purple"
          svgSrc={businessSVG}
        />
        <MakeAMarkSection />
        <SignupSection />
      </main>
      <Footer />
    </div>
  );
}

export default App;
