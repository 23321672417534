import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import backgroundImage from '../assets/images/phone-mockup.png';

const HeroSection = () => (
  <section 
    className="h-screen flex items-end bg-cover bg-center relative" 
    style={{backgroundImage: `url(${backgroundImage})`}}
  >
    <div className="absolute bottom-0 w-full bg-black bg-opacity-50 py-12">
      <div className="container mx-auto px-6">
        <div className="max-w-2xl">
          <h1 className="text-3xl font-light text-white mb-4">Altruence</h1>
          <h2 className="text-6xl font-reckless text-white mb-4">Connecting People &<br />Purpose</h2>
          <p className="text-white text-xl mb-8">
            Altruence is a dynamic platform that connects volunteers, nonprofits, and
            corporations to transform community engagement by integrating cutting-edge
            technology and fostering meaningful interactions.
          </p>
          <button className="bg-altruence-yellow text-black px-6 py-3 rounded-full text-lg font-gt-pressura flex items-center">
            Sign Up For Early Access
            <FaArrowRight className="ml-2" />
          </button>
        </div>
      </div>
    </div>
  </section>
);

export default HeroSection;
