import React from 'react';

const Header = () => (
  <header className="fixed top-0 left-0 right-0 z-50 bg-white bg-opacity-75 backdrop-blur-md">
    <nav className="container mx-auto px-6 py-3 flex justify-between items-center">
      <div className="text-3xl font-bold">Altruence</div>
      <div>
        <button className="bg-altruence-yellow text-black px-6 py-2 rounded-full font-gt-pressura">
          Sign Up For Early Access
        </button>
      </div>
    </nav>
  </header>
);

export default Header;