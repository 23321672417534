import React from 'react';

const MissionStatement = () => (
  <section className="py-20 bg-altruence-bg">
    <div className="container mx-auto px-6">
      <h2 className="text-5xl font-reckless leading-tight mb-10 text-black">
        We're on a mission to eliminate the friction in volunteering, enabling seamless connections between passionate individuals and impactful causes.
      </h2>
    </div>
  </section>
);

export default MissionStatement;