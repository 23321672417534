import React from 'react';

const ConnectingSection = ({ title, description, buttonText, svgSrc, bgColor, reverse }) => (
  <section className={`py-20 ${bgColor}`}>
    <div className={`container mx-auto px-6 flex items-center ${reverse ? 'flex-row-reverse' : ''}`}>
      <div className="w-1/2 px-10">
        <h3 className="text-4xl font-bold mb-6 text-black">{title}</h3>
        <p className="text-xl mb-6 text-black">{description}</p>
        <button className="border border-black px-6 py-2 rounded-full font-gt-pressura text-black hover:bg-black hover:text-white transition-colors">
          {buttonText}
        </button>
      </div>
      <div className="w-1/2">
        <div className="relative">
          <img src={svgSrc} alt={title} className="w-full"/>
        </div>
      </div>
    </div>
  </section>
);

export default ConnectingSection;
